<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-input size="small" v-model="query.phone" clearable placeholder="请输入手机号"
					style="width: 160px;margin-right: 10px;"></el-input>
				<el-input size="small" v-model="query.companyName" clearable placeholder="请输入企业名称"
					style="width: 160px;margin-right: 10px;"></el-input>
				<el-input size="small" v-model="query.city" clearable placeholder="请输入城市"
					style="width: 120px;margin-right: 10px;"></el-input>
				<el-input size="small" v-model="query.iccid" clearable placeholder="请输入ICCID"
					style="width: 120px;margin-right: 10px;"></el-input>
				<el-select size="small" v-model="query.type" filterable clearable placeholder="请选择类型"
					style="width: 120px;margin-right: 10px;" @change="getSJSZInventoryStatistics">
					<el-option label="新增" :value="1">新增</el-option>
					<el-option label="换卡" :value="2">换卡</el-option>
					<el-option label="补卡" :value="3">补卡</el-option>
					<el-option label="退卡" :value="4">退卡</el-option>
				</el-select>
				<el-select size="small" v-model="query.cardType" filterable clearable placeholder="请选择品牌"
					style="width: 120px;margin-right: 10px;" @change="getSJSZInventoryStatistics">
					<el-option v-for="item in cardType" :key="item.label" :label="item.label"
						:value="item.label"></el-option>
				</el-select>
				<el-select size="small" v-model="query.salesStaff" filterable clearable placeholder="请选择销售"
					style="width: 120px;margin-right: 10px;" @change="getSJSZInventoryStatistics">
					<el-option v-for="item in salesStaffList" :key="item" :label="item" :value="item"></el-option>
				</el-select>
				<el-date-picker v-show="showForm" v-model="timeArr" type="daterange"
					style="width: 260px;margin-right: 10px;" unlink-panels range-separator="至" start-placeholder="开始日期"
					end-placeholder="结束日期" @change="getTime()" size="small"></el-date-picker>
				<el-button size="small" type="primary" :class="[theme]" @click="getData()">搜索</el-button>
				<el-button size="small" type="primary" :class="[theme]" @click="exportData">导出</el-button>
				<el-button size="small" type="primary" :class="[theme]" @click="toOptionalNumber">批量退卡</el-button>
			</div>

			<div style="width: 100%;height: 20px;clear: both;"></div>
			<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
				:row-style="{ height: '20px' }" :cell-style="{ padding: '6px' }"
				:header-cell-style="{ 'text-align': 'center' }" :row-class-name="tableRowClassName"
				@selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55" align="center"></el-table-column>
				<el-table-column prop="salesStaff" label="销售姓名" align="center" width="90"></el-table-column>
				<el-table-column prop="phone" label="手机号码" align="center" width="120"></el-table-column>
				<el-table-column prop="iccid" label="ICCID" align="center" width="160"></el-table-column>
				<el-table-column prop="companyName" label="企业名称" align="center" width="230"></el-table-column>
				<el-table-column prop="city" label="号码城市" align="center"></el-table-column>
				<el-table-column prop="type" label="类型" align="center" width="60">
					<template #default="scope">
						<span v-if="scope.row.type == '退卡'" style="color: red;">{{ scope.row.type }}</span>
						<span v-else>{{ scope.row.type }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="cardType" label="号码品牌" align="center"></el-table-column>
				<el-table-column prop="address" label="地址" align="center" width="300"></el-table-column>
				<el-table-column prop="time" label="出库时间" align="center" width="155"></el-table-column>
				<el-table-column label="操作" width="80" align="center">
					<template #default="scope">
						<!-- <el-button size="small" type="text" icon="el-icon-edit">出库</el-button> -->
						<!-- <el-button size="small" type="text" icon="el-icon-delete" style="color: #f56c6c;">删除</el-button> -->
						<el-button size="small" type="primary" :class="[theme]" v-if="scope.row.type !== '退卡'"
							@click="returnCard(scope.row.id)">退卡</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination background layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex" :page-sizes="[10, 30, 90, 200]" :page-size="query.pageSize"
					:total="pageTotal" @size-change="handleSizeChange"
					@current-change="handlePageChange"></el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		SJSZInventoryDeliveryRecord,
		SJSZInventoryCardType,
		SJSZInventorystatistics,
		SJSZInventoryprovince,
		getSalesStaffList,
		getCompanyList,
		SJSZInventoryDeliveryStorage,
		SJSZInventoryExportRecord,
		returnCard
	} from '../api/setMeal.js';
	import { fetchDefaultCompanies, fetchCompanyByAgentId } from '../api/companyIndex';
	import { fetchAgent } from '../api/agentIndex';
	import qs from 'qs';
	import QRCode from 'qrcodejs2';
	export default {
		name: 'SJDeliveryRecord',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				theme3: localStorage.getItem('theme3'),
				query: {
					phone: '',
					type: '',
					companyName: '',
					cardType: '',
					iccid: '',
					city: '',
					salesStaff: '',
					pageIndex: 1,
					pageSize: 10
				},
				tableData: [],
				pageTotal: 0,
				cityList: [],
				provinceList: [],
				cardType: [],
				timeArr: [],
				addVisible: false,
				multipleSelection: [],
				agents: [],
				companys: [],
				ids: '',
				isAdmin: false,
				isAgent: false,
				role: '',
				salesStaffList: [],
				companyList: []
			};
		},

		created() {
			this.role = localStorage.getItem('ms_role');
			this.getSJSZInventoryCardType();
			this.getSJSZInventoryStatistics();
			this.getSalesStaffList();
			this.getCompanyList();
		},
		methods: {
			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			getTime() {
				let startTime = new Date(new Date());
				let endTime = new Date();
				this.timeArr.push(startTime);
				this.timeArr.push(endTime);
			},
			getData() {
				if (this.timeArr.length > 0) {
					this.query.startTime = this.toDateTimeStr(this.timeArr[0]);
					this.query.endTime = this.toDateTimeStr(this.timeArr[1]);
				}
				let data = {
					phone: this.query.phone,
					type: this.query.type,
					companyName: this.query.companyName,
					cardType: this.query.cardType,
					city: this.query.city,
					salesStaff: this.query.salesStaff,
					pageIndex: this.query.pageIndex,
					pageSize: this.query.pageSize,
					endTime: this.query.endTime,
					startTime: this.query.startTime,
					iccid: this.query.iccid
				};
				SJSZInventoryDeliveryRecord(data).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
						this.$message.success(res.message);
					} else {
						this.$message.error(res.message);
					}
				});
			},
			toDateTimeStr(date) {
				let y = date.getFullYear();
				let m = date.getMonth() + 1;
				let d = date.getDate();
				if (m < 10) {
					m = '0' + m;
				}
				if (d < 10) {
					d = '0' + d;
				}

				return y + '-' + m + '-' + d;
			},
			getSJSZInventoryStatistics() {
				this.query.city = '';
				let data = {
					cardType: this.query.cardType,
					province: this.query.province
				};
				SJSZInventorystatistics(data).then(res => {
					if (res.code == 200) {
						this.cityList = res.data;
						this.getSJSZInventoryprovince();
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getSJSZInventoryprovince() {
				SJSZInventoryprovince(this.query.cardType).then(res => {
					if (res.code == 200) {
						this.provinceList = res.data;
						this.getData();
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getSJSZInventoryCardType() {
				SJSZInventoryCardType().then(res => {
					if (res.code == 200) {
						this.cardType = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},

			getSalesStaffList() {
				getSalesStaffList().then(res => {
					if (res.code == 200) {
						this.salesStaffList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getCompanyList() {
				getCompanyList().then(res => {
					if (res.code == 200) {
						this.companyList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},

			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getData();
			},
			handleSizeChange(val) {
				this.query.pageSize = val;
				this.getData();
			},
			exportData() {
				SJSZInventoryExportRecord(this.query).then(res => {
					this.$message.success('导出中');
					const blob = new Blob([res], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
					});
					let downloadElement = document.createElement('a');
					let href = window.URL.createObjectURL(blob);
					downloadElement.href = href;
					let fileName = '出库记录' + '.xlsx';
					downloadElement.download = fileName;
					document.body.appendChild(downloadElement);
					downloadElement.click();
					document.body.removeChild(downloadElement);
					window.URL.revokeObjectURL(href);
				});
			},
			// 多选操作
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			toOptionalNumber() {
				for (let i = 0; i < this.multipleSelection.length; i++) {
					this.ids += this.multipleSelection[i].id + ',';
				}
				if (this.ids === '') {
					this.$message.error(`请选择数据`);
					return;
				} else {
					this.returnCard(this.ids);
				}
			},
			returnCard(id) {
				let data = {
					ids: id
				};
				returnCard(data).then(res => {
					if (res.code == 200) {
						this.$message.success(res.data);
						this.getData();
					} else {
						this.$message.error(res.message);
					}
				});
			}
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	.city-box {
		margin: 20px 0;
	}

	.city-box div {
		width: 140px;
		float: left;
		margin-right: 20px;
	}

	.city-box div span {
		margin-right: 6px;
	}

	.customer .el-upload {
		display: flex !important;
		flex-direction: column !important;
		align-items: center !important;
		justify-content: center !important;
		width: 100% !important;
		line-height: 50px !important;
	}

	.down {
		border: 1px solid #dcdfe6;
		background: #4f7afd;
		color: #ffffff;
		transition: 0.1s;
		font-weight: 500;
		padding: 6px 12px;
		font-size: 12px;
		border-radius: 4px;

		display: inline-block;
	}
</style>